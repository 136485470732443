import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@mri-platform/dsg/core';
import { LogoutPromptService, isNotNullOrUndefined, keysOf } from '@mri-platform/shared/core';
import { AuthService, User } from '@mri/angular-wfe-proxy-oidc';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, combineLatest } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { map } from 'rxjs/operators';

interface ComponentState {
  userInfo: User;
  isOperationalUser: boolean;
  currentClientId: string;
}

type PublicState = ComponentState;

interface Projections {
  currentClientInfo: string;
}

type ViewModel = PublicState & Projections;

const initialPublicState: PublicState = {
  userInfo: undefined as never,
  isOperationalUser: true,
  currentClientId: ''
};

const initialState: ComponentState = {
  ...initialPublicState
};

@Component({
  templateUrl: './shell.component.html',
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        display: block;
      }

      /*PROBLEM: The z-index of the new nav-bar is above the z-index of dialog
      TODO: Remove the below workaround once the issue is fixed - https://mripride.atlassian.net/browse/PXAG-4971 */
      :host ::ng-deep .mri-menu-bar {
        z-index: 10001 !important;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ShellComponent {
  vm$: Observable<ViewModel>;
  constructor(
    private state: RxState<ComponentState>,
    private authService: AuthService,
    private logoutPromptService: LogoutPromptService,
    private router: Router
  ) {
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...
    this.state.connect(this.authService.userLoaded$, (_, userInfo) => ({
      userInfo,
      isOperationalUser: userInfo.canSwitchClients
    }));

    this.state.connect('currentClientId', this.authService.currentClientId$.pipe(isNotNullOrUndefined()));

    // Create projections (calculations from ComponentState)...
    const currentClientInfo$ = this.state.select(selectSlice(['userInfo', 'currentClientId'])).pipe(
      map(({ userInfo, currentClientId }) => {
        if (currentClientId === userInfo.clientName) return currentClientId;
        return `${currentClientId} - ${userInfo.clientName}`;
      })
    );

    // Create ViewModel (Projections + PublicState)...

    const publicState$ = this.state.select(selectSlice(keysOf(initialPublicState)));
    this.vm$ = combineLatest([publicState$, currentClientInfo$]).pipe(
      map(([publicState, currentClientInfo]) => ({ ...publicState, currentClientInfo })),
      tag('vm')
    );
    // side effects if any...
    // none
  }

  async handleLogout() {
    const canProceed = await this.logoutPromptService.showLogoutPrompt$().toPromise();
    if (canProceed) {
      this.authService.logOut();
    }
  }

  openClientDrawer() {
    this.router.navigate(['', { outlets: { drawer: [AppRoutes.SwitchClient.path, 'switch'] } }], {
      queryParamsHandling: 'preserve'
    });
  }
}
