<!--
IMPORTANT: do NOT use an `ngIf` at the level of `mri-shared-shell` component. Doing so will result in a delay in rendering
the `router-outlet` inside of shell component template. Such a delay will cause the constructor of the routed component
to be executed AFTER the router events for that component having already being fired. This is problematic if that routed component
needs to listen to these router events in order to perform some logic EG the logic inside of `MasterListBasePageComponent.selectedItemId`
-->
<mri-shared-shell>
  <mri-shared-page-footer
    *ngIf="vm$ | async as vm"
    pageFooter
    [user]="vm.userInfo"
    [currentClientId]="vm.currentClientInfo"
    [isEnableSwitchClient]="vm.isOperationalUser"
    (handleLogout)="handleLogout()"
    (openClientDrawer)="openClientDrawer()"
  >
  </mri-shared-page-footer>
</mri-shared-shell>

<router-outlet name="modal"></router-outlet>
