import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@mri-platform/dsg/core';
import { RouteMetadata } from '@mri-platform/shared/core';
import { MainMenuItemService } from '@mri-platform/shared/shell';
import { DrawerMenuItem, LinkMenuItem } from '@mri-platform/shared/ui-main-nav-bar';
import { AskAgoraMenuItem } from '@mri-platform/shared/ui-main-nav-bar-updated';
import { AskAgoraConfig } from '@mri/ask-agora-insights-angular';

@Injectable()
export class AppMainMenuItemService implements MainMenuItemService {
  private dashboards: LinkMenuItem = {
    iconName: 'report',
    title: 'Dashboards',
    supportsToggle: true,
    ...AppRoutes.Dashboards.toMenuInfo()
  };

  private dashboardManagement: LinkMenuItem = {
    iconName: 'wrench',
    title: 'Management',
    ...AppRoutes.DashboardManagement.toMenuInfo()
  };

  private mriSettings: DrawerMenuItem = {
    iconName: 'gear',
    title: 'Administration',
    links: [
      { text: 'User security settings', ...AppRoutes.UserSettings.toMenuInfo() },
      { text: 'Reconciliation screen', ...AppRoutes.Reconciliation.toMenuInfo() },
      { text: 'Client Onboard Configuration', ...AppRoutes.ClientOnboardConfig.toMenuInfo() }
    ]
  };

  private about: LinkMenuItem = {
    iconName: 'information-circle',
    title: 'About',
    ...AppRoutes.About.toMenuInfo(),
    opener: (_: string) =>
      this.router.navigate(['', { outlets: { modal: [AppRoutes.About.path, 'details'] } }], {
        queryParamsHandling: 'preserve'
      })
  };

  askAgoraMetadata: RouteMetadata = {
    featureName: 'askAgora'
  };

  private askAgoraMenuItem: AskAgoraMenuItem = {
    // icon will be provided by ask-agora component this value will not be used
    iconName: 'information-circle',
    baseApiUrl: this.askAgoraConfig.baseApiUrl,
    assetSource: this.askAgoraConfig.assetSource,
    isAskAgora: true,
    title: 'Ask Agora',
    metadata: this.askAgoraMetadata
  };

  constructor(
    private router: Router,
    private askAgoraConfig: AskAgoraConfig
  ) {}

  get items() {
    return [this.askAgoraMenuItem, this.dashboards, this.dashboardManagement, this.mriSettings, this.about];
  }
}
